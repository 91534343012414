'use client'
import { _themed } from '@aw/utils'
import React, { Dispatch, useEffect, useState } from 'react'
import { useAuth } from './../hooks/auth'
import UseAfterLogin from './../hooks/useAfterLogin'
import axios from './../lib/axios'

interface workSpaceContextInterface {
    organizations: {} | []
    currentOrganization: string | number | null
    setCurrentOrganization: Dispatch<React.SetStateAction<string | null | number>>
    currOrgName: string
    userPermissions: string[]
}

const WorkSpaceContext = React.createContext<workSpaceContextInterface>({
    organizations: [],
    currentOrganization: null,
    setCurrentOrganization: () => null,
    currOrgName: '',
    userPermissions: [],
})

const WorkSpaceProvider = ({ children }) => {
    const { user } = useAuth()

    const fetcher = url => axios.get(url).then(res => res)

    // const { data: organizations } = useSWRImmutable(user ? `/users/${user.id}/organizations` : false, fetcher, { refreshInterval: 0 })

    const [currentOrganization, setCurrentOrganization] = useState<string | number | null>(null)

    const { getAfterLoginData, data } = UseAfterLogin('/user/organizations')

    const [currOrgName, setCurrOrgName] = useState('')
    const [userPermissions, setUserPermissions] = useState<string[]>([])

    const getPermissions = async () => {
        const res = await axios.get(`/organization/${currentOrganization}/user/${user.id}/permissions`)

        setUserPermissions(res.data.data)
    }

    useEffect(() => {
        if (currentOrganization) return
            ; (async () => {
                await getAfterLoginData(user)
            })()
    }, [user])

    useEffect(() => {
        if (!currentOrganization || !data) return
        setCurrOrgName(data?.find(d => d.uid === currentOrganization)?.name || '')
        getPermissions()
    }, [currentOrganization])

    useEffect(() => {
        if (typeof window == 'undefined' || !data || !data.length || !user) return

        let storedOrg = localStorage.getItem(`${user.id}organization`)

        if (!storedOrg) {
            localStorage.setItem(`${user.id}organization`, data[0].uid)
            setCurrentOrganization(data[0].uid)
        } else {
            setCurrentOrganization(storedOrg)
        }
    }, [data])

    return (
        <WorkSpaceContext.Provider value={{ organizations: data, currentOrganization, setCurrentOrganization, currOrgName, userPermissions }}>
            {children}
        </WorkSpaceContext.Provider>
    )
}

export { WorkSpaceProvider, WorkSpaceContext }
