import { createSlice } from '@reduxjs/toolkit'

const JobState = {
    id: null,
    title: '',
    location: '',
    role: '',
    description: '',
    excerpt: '',
    pinned: false,
    hire_tenure: 'FULL_TIME',
    source_currency_id: 1,
    target_currency_id: 1,
    salary_from: 0,
    salary_to: 0,
    compensation: 'Hourly',
    required_experiance: '',
    type: 'Primary',
    qualification: '',
    application_deadline: '',
    tags: [],
    status: 'open',
    slug: '',
    interviewer_ids: [],
    question_id: []
}

const initialState = {
    job: JobState,
    loader: {
        loading: false,
        text: '',
    },
}

export const job = createSlice({
    name: 'job',
    initialState,
    reducers: {
        setLoader: (state, action) => {
            state.loader.loading = action.payload.loading
            state.loader.text = action.payload.text
        },
        setProperty: (state, action) => {
            state.job[action.payload.property] = action.payload.value
        },
        resetJob: () => initialState,
        setJob: (state, action) => {
            Object.entries(action.payload).forEach(([key, value]) => {
                state.job[key] = value
            });

        },
    },
})

export const { setProperty, resetJob, setLoader, setJob } = job.actions

export default job.reducer
