import { report } from '@aw/lib'
import React, { Component, ErrorInfo, ReactNode } from 'react'
import AbroadworksLogo from '../../public/static/logo.png'
import Heading from "../Common/Heading"
import Button from "../Common/Button"
interface Props {
    children: ReactNode
}

interface State {
    hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
    state: State

    constructor(props: Props) {
        super(props)

        // Define a state variable to track whether is an error or not
        this.state = { hasError: false }
    }
    static getDerivedStateFromError(error: Error): State {
        // Update state so the next render will show the fallback UI

        return { hasError: true }
    }
    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can use your own error logging service here
        report({ error, errorInfo })
    }
    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI

            return (
                <div className="h-screen w-screen justify-items-stretch flex flex-col py-6">
                    <header className="px-12 flex-1">
                        <a href="#">
                            <span className="sr-only">Your Company</span>
                            <img
                                className="h-8 w-auto sm:h-8"
                                alt='Abroadworks'
                                src={AbroadworksLogo.src}
                            />
                        </a>
                    </header>
                    <section className="flex h-full items-center justify-center w-full ">
                        <div className="flex flex-col gap-4 w-full items-center">
                            <img src='/static/vectors/oops.svg' alt='error 404' />
                            <Heading size="h3" className='!text-center'>
                                Oops! There's a problem with this page.
                            </Heading>
                            <p className="text-neutralgray-7 text-sm md:max-w-[29rem] text-center px-4">
                                We apologize for the inconvenience. It seems like there's an issue with the page you're trying to access. Don't worry, our team is here to help you!
                            </p>
                            <p className="text-neutralgray-7 text-sm md:max-w-[29rem] text-center px-4">

                                Please try refreshing the page first to see if that resolves the issue. If the problem persists, feel free to contact us for support at support@abroadworks.com
                            </p>
                            <div>
                                <a href="#" className="text-sm font-semibold leading-7 text-indigo-600" onClick={() => this.setState({ hasError: false })}>
                                    <Button
                                        color="primary"
                                    >
                                        Refresh
                                    </Button>
                                </a>
                            </div>
                        </div>

                    </section>
                </div>
            )
        }


        // Return children components in case of no error

        return this.props.children
    }
}

export default ErrorBoundary
