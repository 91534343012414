import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import clsx from 'clsx'
import React from 'react'

const transitions = Object.freeze({
    grow: {
        enterFrom: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
        enterTo: 'opacity-100 translate-y-0 sm:scale-100',
        leave: 'ease-in duration-200',
        leaveFrom: 'opacity-100 translate-y-0 sm:scale-100',
        leaveTo: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
    },
    tilt: {
        enterFrom: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
        enterTo: 'opacity-100 translate-y-0 sm:scale-100 animate-tilt-in-fwd-tr',
        leave: 'ease-in duration-200',
        leaveFrom: 'opacity-100 translate-y-0 sm:scale-100',
        leaveTo: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
    },
    slide: {
        enterFrom: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
        enterTo: 'opacity-100 translate-y-0 sm:scale-100 animate-slide-in-elliptic-top-fwd',
        leave: 'ease-in duration-200',
        leaveFrom: 'opacity-100 translate-y-0 sm:scale-100',
        leaveTo: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
    },
    pulse: {
        enterFrom: 'opacity-0 translate-y-1',
        enterTo: 'opacity-100 -translate-y-5',
        leave: 'ease-in duration-200',
        leaveFrom: 'opacity-100',
        leaveTo: 'opacity-0 translate-y-5 sm:translate-y-0',
    },
    fade: {
        enterFrom: 'opacity-0 scale-105',
        enterTo: 'opacity-100 scale-100',
        leave: 'ease-in duration-200',
        leaveFrom: 'opacity-100 scale-100',
        leaveTo: 'opacity-0 scale-105',
    },
})

const sizes = Object.freeze({
    xs: 'max-w-xs',
    sm: 'max-w-sm',
    md: 'max-w-md',
    lg: 'max-w-lg',
    xl: 'max-w-xl',
    '2xl': 'max-w-2xl',
    '3xl': 'max-w-3xl',
    '4xl': 'max-w-4xl',
    '5xl': 'max-w-5xl',
    '6xl': 'max-w-6xl',
    '7xl': 'max-w-7xl',
})

function DialogComponent({ open, className, transition, size, isDrawer, children }, ref) {
    const initialFocusRef = React.useRef(null)

    return (
        <Transition show={open} as={React.Fragment}>
            <Dialog as="div" className="relative" onClose={() => { }} initialFocus={initialFocusRef}>
                {isDrawer || (
                    <TransitionChild
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-[#121416] bg-opacity-75 transition-opacity z-[101]" />
                    </TransitionChild>
                )}

                {isDrawer && <div className="fixed inset-0" />}

                {isDrawer ? (
                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none z-[102] fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                                <TransitionChild
                                    as={React.Fragment}
                                    ref={ref}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full">
                                    <DialogPanel className={clsx(className, sizes[size], 'pointer-events-auto w-screen ')}>
                                        <div className="flex h-full flex-col overflow-y-auto bg-white shadow-xl">{children(initialFocusRef)}</div>
                                    </DialogPanel>
                                </TransitionChild>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="fixed flex justify-center  inset-0 z-[102] overflow-y-auto h-full py-12">

                        <TransitionChild as={React.Fragment} ref={ref} enter="ease-out duration-300" {...transitions[transition]}>
                            <DialogPanel
                                className={clsx(
                                    className,
                                    sizes[size],
                                    'relative h-max transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-[95%]'
                                )}>
                                {children(initialFocusRef)}

                            </DialogPanel>
                        </TransitionChild>
                    </div>
                )}
            </Dialog>
        </Transition>
    )
}

export default React.forwardRef(DialogComponent)
