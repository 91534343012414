import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AdjustmentState, Adjustment } from "../types"

const initialState: AdjustmentState = {
    adjustments: [],
    archived: [],
    user: null,
    email: '',
    name: '',
}

const adjustment: Adjustment = {
    id: Date.now() + Math.floor(Math.random() * 1000),
    pay_period: 'RECURRING',
    apply_times: 1,
    applied_times: 0,
    system_adjustment_type: '',
    custom_type_id: null,
    type_name: '',
    effective_from: '',
    active: true,
    amount: 0,
    calculate_as: 'ADD',
    description: 'test',
}

const adjustmentSlice = createSlice({
    name: 'adjustmentSlice',
    initialState,
    reducers: {
        setAdjustments: (state, action: PayloadAction<Adjustment[]>) => {
            state.adjustments = action.payload
        },
        setArchivedAdjustments: (state, action: PayloadAction<Adjustment[]>) => {
            state.archived = action.payload
        },
        setUser: (state, action: PayloadAction<number>) => {
            state.user = action.payload
        },
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload
        },
        setName: (state, action: PayloadAction<string>) => {
            state.name = action.payload
        },
        addAdjustment: state => {
            const newAdjustment = {
                ...adjustment,
                id: Date.now() + 'id',
            }
            state.adjustments.push(newAdjustment)
        },
        removeAdjustment: (state, action: PayloadAction<Adjustment['id']>) => {
            state.adjustments = state.adjustments.filter(adjustment => adjustment.id !== action.payload)
        },
        updateAdjustment: (
            state,
            action: PayloadAction<{
                adjustmentId: Adjustment['id']
                property: keyof Adjustment
                value: Adjustment[keyof Adjustment]
            }>
        ) => {
            const { adjustmentId, property, value } = action.payload
            const adjustment = state.adjustments.find(adjustment => adjustment.id === adjustmentId)
            if (adjustment) {
                adjustment[property] = value as never
            }
        },
    },
})

export const {
    setAdjustments,
    setArchivedAdjustments,
    setUser,
    setEmail,
    setName,
    addAdjustment,
    removeAdjustment,
    updateAdjustment
} = adjustmentSlice.actions

export default adjustmentSlice.reducer
