import useVideoPlayerPopup from '@/hooks/useVideoPlayerPopup'
import { motion } from 'framer-motion'
import { ArrowsOut, ArrowSquareDownLeft, FrameCorners, XCircle } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import { Resizable } from 'react-resizable'
import Moveable from 'react-moveable'
import useIntersection from "@/hooks/useIntersection"
interface Props {
    containerRef: React.RefObject<Element>
}

const VideoPlayerPopup = ({ containerRef }: Props) => {
    const [size, setSize] = React.useState<{ width: number; height: number }>({ width: 768, height: 432 })
    const [drag, setDrag] = React.useState<boolean>(true)
    const targetRef = React.useRef<HTMLDivElement>(null);
    const moveableRef = React.useRef<MoveAble>(null);
    const { toggleVideoPlayer, setToggleVideoPlayer } = useVideoPlayerPopup()

    const iframeRef = React.useRef(null)
    const mainContainerRef = React.useRef(null)

    // FOR PIP MODE
    useEffect(() => {
        if (iframeRef && iframeRef.current) {
            iframeRef.current.addEventListener('enterpictureinpicture', event => {
                // Video entered Picture-in-Picture mode.
                const pipWindow = event.pictureInPictureWindow
                setToggleVideoPlayer({ url: '' })
            })

            iframeRef.current.addEventListener('leavepictureinpicture', e => {
                setToggleVideoPlayer({ url: e.target.src })

                // Video left Picture-in-Picture mode.
            })
        }
    }, [iframeRef.current])

    // WINDOW RESIZABKE HANDLING
    const onResizeStart = (event, { element, size, handle }) => {
        setDrag(false)
        setSize({ width: size.width, height: size.height })
    }

    // return (
    //     <div className="fixed top-1/4 left-0 z-[999]">
    //         {/* IF URL EXIST IN VIDEO TOGGLEVIDEOPLAYER STATE THEN VISIBLE */}
    //         {toggleVideoPlayer.url ? (
    //             // RESIZABLE WRAPPER FOR RESIZING
    //             <Resizable
    //                 handle={<FrameCorners className="absolute top-2 right-12 cursor-nesw-resize " color="white" weight="regular" size={30} />}
    //                 height={size.height}
    //                 width={size.width}
    //                 onResize={onResizeStart}
    //                 onResizeStop={() => setDrag(true)}>
    //                 {/* FOR DRAGGING */}
    //                 <motion.div
    //                     ref={mainContainerRef}
    //                     dragElastic={0.1}
    //                     dragTransition={{
    //                         bounceDamping: 8,
    //                         power: 0,
    //                         min: 0,
    //                         max: 100,
    //                         timeConstant: 250,
    //                     }}
    //                     className="absolute top-[35%] left-[38%] flex h-fit w-fit cursor-move flex-col overflow-hidden rounded-lg border bg-black shadow-lg"
    //                     drag={drag}
    //                     dragConstraints={containerRef}>
    //                     <div style={{ width: `${size.width}px`, height: `${size.height}px` }}>
    //                         <div className="absolute right-0 flex h-12 w-max items-center bg-black bg-opacity-30 py-1 text-center shadow-white z-[10] ">
    //                             <XCircle
    //                                 onClick={() => setToggleVideoPlayer({ url: '' })}
    //                                 className="ml-auto mr-2 cursor-pointer "
    //                                 color="white"
    //                                 size={30}
    //                                 weight="fill"
    //                             />
    //                         </div>

    //                         <video ref={iframeRef} controls className="h-full w-full cursor-auto" src={toggleVideoPlayer.url}></video>
    //                     </div>
    //                 </motion.div>
    //             </Resizable>
    //         ) : null}
    //     </div>
    // )

    const [frame, setFrame] = useState({
        width: 500,
        height: 281,
    });
    const [showMoveableControls, setShowMoveableControls] = useState(false);


    //     function isOverflowing(e):string {
    //         if (!targetRef.current || !containerRef.current) return console.error('no ref')
    //         const draggableRect = e.target.getBoundingClientRect();
    //         const containerRect = containerRef.current.getBoundingClientRect();
    //         console.log(draggableRect, containerRect)


    //         if(draggableRect.x > containerRect.width){
    // return 'left'
    //         }else if(draggableRect.y > containerRect.height || draggableRect.y <= 0){
    // 't'
    //         }



    //         if ((draggableRect.x > containerRect.width || draggableRect.x < 0) || (draggableRect.y > containerRect.height || draggableRect.y < 0)) {
    //             console.log('Draggable is overflowing its container.'); return true

    //         } else {
    //             console.log('Draggable is not overflowing its container.'); return false
    //         }
    //         return (draggableRect.width > containerRect.width || draggableRect.height > containerRect.height);
    //     }


    return (
        <div className="fixed top-1/2 left-1/3 -translate-x-1/3 -translate-y-1/2 z-[999]">
            {/* <div className="container w-screen h-screen " ref={moveableRef}>

            </div> */}
            {/* IF URL EXIST IN VIDEO TOGGLEVIDEOPLAYER STATE THEN VISIBLE */}
            {toggleVideoPlayer.url ? (
                // RESIZABLE WRAPPER FOR RESIZING


                <div className="root">
                    <div className="containe w-0 h-0 absolute -top-52 right-0" >
                        <div className="target" ref={targetRef} style={{
                            // maxWidth: "auto",
                            // maxHeight: "auto",
                            // minWidth: "auto",
                            // minHeight: "auto",
                            width: `${frame.width}px`,
                            height: `${frame.height}px`,

                        }}>
                            <div className="relative left-0 ">
                                <XCircle
                                    onClick={() => setToggleVideoPlayer({ url: '' })}
                                    className="absolute z-10 right-0 top-1.5 ml-auto mr-2 cursor-pointer "
                                    color="white"
                                    size={30}
                                    weight="fill"
                                />

                                <video onLoadedData={() => setShowMoveableControls(true)} ref={iframeRef} controls className="h-full w-full cursor-auto" src={toggleVideoPlayer.url}></video>
                            </div>

                        </div>
                    </div>
                    <Moveable
                        // bounds={{ "left": 100, "top": 0, "right": 0, "bottom": 0, "position": "client" }}
                        // snappable={true}
                        // snapDirections={{ bottom: true, top: true, left: true, right: true }}
                        origin={false}
                        ref={moveableRef}
                        target={targetRef}
                        draggable={showMoveableControls}
                        throttleDrag={1}
                        edgeDraggable={showMoveableControls}
                        startDragRotate={0}
                        throttleDragRotate={0}
                        snapGridHeight={frame.height}
                        resizable={showMoveableControls}
                        keepRatio={true}
                        throttleResize={1}
                        renderDirections={["se"]}
                        onDrag={e => {
                            // console.log(e.transform)
                            // if (!isOverflowing(e)) 
                            e.target.style.transform = e.transform;
                        }}
                        onResize={({ target, width, height, delta }) => {
                            delta[0] && (target.style.width = `${width}px`);
                            delta[1] && (target.style.height = `${height}px`);
                            setFrame({
                                ...frame,
                                width,
                                // height,
                            });
                        }}

                    />
                </div>
            ) : null
            }
        </div >
    )
}

export default VideoPlayerPopup




