import cn from 'clsx'
import { CheckCircle } from 'phosphor-react'
import useTheme from '../../aw-hooks/src/useTheme'
import { _themed } from '../../aw-utils/src/util'
import Input from './Input'
import React from "react"

const IconInput = ({ icon = null, iconClassName = '', iconSize = 20, iconWeight = 'regular', ...props }, ref) => {
    const { theme } = useTheme()
    const Icon = icon || CheckCircle
    const left = (
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <Icon className={`${iconClassName} text-gray-400`} size={iconSize} weight={iconWeight} />
        </div>
    )

    return <Input left={left} {...props} className={cn('pl-10', props?.className)} ref={ref} />
}

export default React.forwardRef(IconInput)
