import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'

interface RemarksState {
    remarkId: string | null
    customRemark: string | null
}

const initialState: RemarksState = {
    remarkId: null,
    customRemark: null,
}

export const remarks: Slice<RemarksState> = createSlice({
    name: 'remarks',
    initialState,
    reducers: {
        setRemarkState: (state, action: PayloadAction<RemarksState>) => {
            state.remarkId = action.payload.remarkId
            state.customRemark = action.payload.customRemark
        },
    },
})

export const { setRemarkState } = remarks.actions
export default remarks.reducer