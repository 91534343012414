import { useParams, usePathname, useRouter, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'
import useSWR from 'swr'
import axios from '../lib/axios'
import { user } from "@aw/hooks/src/useUserDetails"

export const useAuth = ({ middleware, redirectIfAuthenticated } = {}) => {
    const router = useRouter()
    const query = useSearchParams()
    const pathQuery = useParams()
    const pathname = usePathname()

    const {
        data: user,
        error,
        mutate,
    } = useSWR<user>(
        '/user',
        () =>
            axios
                .get('/user')
                .then(res => res.data)
                .catch(error => {
                    if (error.response.status === 409) {
                        router.push('/verify-email')
                        return
                    }

                    const _e = new Error('An error occurred while fetching the data.')
                    _e.info = error.response
                    _e.status = error.response.status
                    _e.ignore = true
                    throw _e
                }),
        {
            refreshInterval: 30000,
        }
    )

    const csrf = () => axios.get('/sanctum/csrf-cookie')

    const register = async ({ setErrors, ...props }) => {
        await csrf()

        setErrors([])

        axios
            .post('/register', props)
            .then(() => mutate())
            .catch(error => {
                if (error.response.status !== 422) throw error

                setErrors(Object.values(error.response.data.errors).flat())
            })
    }

    const login = async ({ setErrors, setStatus, ...props }) => {
        await csrf()

        setErrors([])
        setStatus(null)

        axios
            .post('/login', props)
            .then(response => {
                if (response.status === 204) {
                    mutate()
                    return
                }

                if (response.status === 200 && response.data?.two_factor) {
                    router.replace('/login/twofactor-challange')
                }
            })
            .catch(error => {
                if (error.response.status === 422) {
                    setErrors(Object.values(error.response.data.errors).flat())
                    return
                }

                const _e = new Error('An error occurred while fetching the data.')
                _e.info = error.response
                _e.status = error.response.status
                _e.ignore = false
                throw _e
            })
    }

    const forgotPassword = async ({ setErrors, setStatus, email }) => {
        await csrf()

        setErrors([])
        setStatus(null)

        axios
            .post('/forgot-password', { email })
            .then(response => setStatus(response.data.status))
            .catch(error => {
                if (error.response.status !== 422) throw error

                setErrors(Object.values(error.response.data.errors).flat())
            })
    }

    const resetPassword = async ({ setErrors, setStatus, ...props }) => {
        await csrf()

        setErrors([])
        setStatus(null)

        axios
            .post('/reset-password', { token: pathQuery?.token, ...props })
            .then(response => router.push('/login?reset=' + btoa(response.data.status)))
            .catch(error => {
                if (error.response.status !== 422) throw error

                setErrors(Object.values(error.response.data.errors).flat())
            })
    }

    const resendEmailVerification = ({ setStatus }) => {
        axios.post('/email/verification-notification').then(response => setStatus(response.data.status))
    }

    const logout = async (redirectToHistory: boolean = false) => {

        if (!error) {
            await axios.post('/logout').then(() => mutate())
        }

        window.location.href = `${process.env.NEXT_PUBLIC_ORG_URL}/login?redirect=${redirectToHistory ? pathname : ''}`
    }

    const redirectionHandler = (type) => {
        switch (type) {
            case 'APPLICANT':
                window.location.href = `${process.env.NEXT_PUBLIC_USER_URL}/dashboard`
                break

            case 'ORG_CLIENT':
                window.location.href = `${process.env.NEXT_PUBLIC_CLIENT_URL}/welcome`
            default: logout()
        }
    }


    useEffect(() => {
        if (middleware === 'guest' && redirectIfAuthenticated && user) router.push(redirectIfAuthenticated)
        if (middleware === 'auth' && error) logout(true)
        if (user && user.type !== 'ORG_INTERNAL_MEMBER') redirectionHandler(user.type)
    }, [user, error])

    return {
        user,
        register,
        login,
        forgotPassword,
        resetPassword,
        resendEmailVerification,
        logout,
    }
}
