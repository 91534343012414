import { useState } from 'react'
import { useSWRConfig } from 'swr'
import axios from './../lib/axios'

const UseAfterLogin = (endpoint: string) => {
    const { mutate } = useSWRConfig()

    const [data, setData] = useState([])
    async function getAfterLoginData(user) {
        if (!user) {
            return []
        } else {
            const res = await axios.get(endpoint)

            setData(res.data.data)
            mutate(`/users/${user.id}/organizations`, res.data.data)
            return res.data.data
        }
    }

    return { getAfterLoginData, data }
}

export default UseAfterLogin
