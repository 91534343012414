import { Modal, useDialog } from '@aw/dialog'
import { network as notify } from '@aw/lib'
import React from 'react'
import toast from 'react-hot-toast'
import { SWRConfig } from 'swr'
import PasswordConfirmation from './PasswordConfirmationDialog'

export default function Swr({ children }) {
    const { show: openConfirmationDialog, hide, id } = useDialog('PASSWORD_CONFIRMATION_DIALOG')

    return (
        <SWRConfig
            value={{
                // refreshInterval: 3000,
                onError: (error, key) => {
                    if (error?.ignore) return

                    if (error.status === 423) {
                        openConfirmationDialog()
                        toast.error('Password confirmation required')
                        return
                    }

                    if (String(error.status).startsWith('5')) {
                        // report(error)
                        notify(error.status)
                    }
                },
                onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
                    // Never retry on 404.
                    if (error.status === 404) return

                    // Only retry up to 5 times.
                    if (retryCount >= 5) return

                    // Retry after 15 seconds on 423.
                    if (error.status === 423) {
                        setTimeout(() => revalidate({ retryCount }), 15000)
                        return
                    }

                    // Never retry for a specific key.
                    // if (key === '/api/user') return

                    // Retry after 7 seconds.
                    setTimeout(() => revalidate({ retryCount }), 7000)
                },
            }}>
            {children}

            <Modal id={id} transition="tilt" size="xs" hideOnClickOutside={false} hideOnEsc={false} drawer={false}>
                {initialRef => <PasswordConfirmation id={id} ref={initialRef} />}
            </Modal>
        </SWRConfig>
    )
}
