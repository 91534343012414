import React from 'react'
import { VideoPlayerContext } from './../components/videoPlayerPopup/context'

const UseVideoPlayerPopup = () => {
    const { setToggleVideoPlayer, toggleVideoPlayer } = React.useContext(VideoPlayerContext)

    return { setToggleVideoPlayer, toggleVideoPlayer }
}

export default UseVideoPlayerPopup
