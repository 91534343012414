import React, { useRef } from 'react'
import VideoPlayerPopup from "..";

interface VideoPlayerContextInterface {
    toggleVideoPlayer: { url: string; pip?: boolean; title?: string }
    setToggleVideoPlayer: React.Dispatch<React.SetStateAction<{ url: string; title?: string; pip?: boolean }>>
}

const VideoPlayerContext = React.createContext<VideoPlayerContextInterface>({
    toggleVideoPlayer: { url: '', title: '', pip: false },
    setToggleVideoPlayer: () => null,
})

const VideoPlayerContextProvider = ({ children }) => {
    const [toggleVideoPlayer, setToggleVideoPlayer] = React.useState({ url: '', title: '', pip: false })
    return <VideoPlayerContext.Provider value={{ toggleVideoPlayer, setToggleVideoPlayer }}><Wrapper>{children}</Wrapper></VideoPlayerContext.Provider>
}

export { VideoPlayerContext, VideoPlayerContextProvider }


const Wrapper = ({ children }) => {
    const ref = useRef(null)
    return <div ref={ref} className="h-full"
    >{children}<VideoPlayerPopup containerRef={ref} /></div>
}