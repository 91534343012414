import { FieldError, IconInput, Label } from '@aw/design-system'
import { useHideDialog } from '@aw/dialog'

import { CheckCircle, Keyhole } from 'phosphor-react'
import React, { ChangeEvent, useState } from 'react'
import useForm from '../../hooks/useForm'
import toast from 'react-hot-toast'
import Button from "../Common/Button"
import Heading from "../Common/Heading"


const UI = ({ id }, initialRef) => {
    const hide = useHideDialog(id)
    const { post, state: formState } = useForm('/user/confirm-password')

    const [password, setPassword] = useState<string>('')

    const submitForm = (event: React.SyntheticEvent) => {
        event.preventDefault()
        post(
            { password },
            {
                onSuccess: response => {
                    if (response.status !== 201) return
                    hide()
                    toast.success('Password confirmed')
                },
            }
        )
    }

    return (
        <form onSubmit={submitForm}>
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Heading>
                            Password Confirmation Required
                        </Heading>
                        <div className="mt-2">
                            <p className="text-sm text-gray-500">For security purpose the action require your password to be confirmed.</p>
                        </div>
                        <div className="mt-4">
                            <Label htmlFor="password" required>
                                Password
                            </Label>

                            <IconInput
                                ref={initialRef}
                                icon={Keyhole}
                                iconClassName="mt-1"
                                iconWeight="fill"
                                id="password"
                                type="password"
                                value={password}
                                className="mt-1 block w-full p-1.5"
                                placeholder="confirm password"
                                disabled={formState.busy}
                                error={formState.isValidationError && formState.errors.password}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                                required
                            />
                            <FieldError state={formState} name="password" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <Button
                    type="submit"
                    size="sm"
                    disabled={formState.busy}
                    loading={formState.busy}
                    color="primary"
                    loadingText="Verifying"
                    className="ml-3">
                    Confirm
                </Button>
                <Button
                    onClick={hide}
                    size="sm"
                    className="sm:ml-3"
                    color="secondary"
                >
                    Cancel
                </Button>
            </div>
        </form>
    )
}

export default React.memo(React.forwardRef(UI))